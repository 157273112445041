import React from 'react';
import './pages.css';

export default function Contact() {
  return (
    <div className="pdf-container">
<iframe
  src="https://docs.google.com/document/d/1UZUF4qZEvvlrnvsHIVjKpxgVWpHKG_BOeQLyUQEbAXQ/preview#heading=h.73zgbr3vwhan"
  title="Resume"
  width="100%"
  height="1000px"
  frameborder="0"
  marginheight="0"
  marginwidth="0"
>
</iframe>
    </div>
  );
}