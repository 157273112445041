import React from 'react';
import PortfolioMain from '../PortfolioMain';

export default function Home() {
  return (
    <div>
      <PortfolioMain />
    </div>
  );
}
