import React from 'react';

function Footer() {
  return (
    <footer className='footer-bottom'>
      2023 johnne &trade;
    </footer>
  );
}

export default Footer;